export const customPrint = () => {
    // 기사 제목 가져오기
    const articleTitle = document.querySelector('h1')?.innerText || '기사 제목 없음';

    // 프린트용 스타일 추가
    const style = document.createElement('style');
    style.id = 'print-style';
    style.innerHTML = `
    @media print {
      @page {
        margin-top: 20mm;
      }
      body::before {
        content: "${articleTitle}"; /* 상단에 기사 제목 삽입 */
        display: block;
        text-align: center;
        font-size: 18px;
        margin-bottom: 10mm;
        font-weight: bold;
      }
    }
  `;
    document.head.appendChild(style);

    // 프린트 창 띄우기
    window.print();

    // 프린트 후 스타일 제거
    document.head.removeChild(style);
};
